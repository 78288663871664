import { Injectable } from '@angular/core';
import { Storage } from '@capacitor/storage';


@Injectable({
  providedIn: 'root'
})
export class MycityService {
mycity = 'default';
cityset = false;
  constructor() {
    this.checkandset();
  }


  getcity()
  {
    return this.mycity;
  }
  getcityset()
  {
    return this.cityset;
  }
  setcity(incity: string)
  {

    Storage.get({key : 'mycity'}).then((e)=>{
      if(!e || !e.value){

        Storage.set({key:'mycity', value: incity}).then(()=>{ console.log('stored :: ' + incity); });
        this.cityset = true;
        this.mycity = incity;
        console.log('stored ::: ' + incity);
        console.log('stored ::: ' + this.mycity);

      }
      else
      {
        Storage.remove({key : 'mycity'}).then(()=>{
          console.log('city reset successful');
        });
        Storage.set({key:'mycity', value: incity}).then(()=>{ console.log('stored : ' + incity); });
        this.cityset = true;
        this.mycity = incity;
      }
    });



  }

 checkandset()
 {
  Storage.get({key : 'mycity'}).then((e)=>{
    if(!e || !e.value){
      console.log('check and set result : city not set');
    }
    else
    {
    this.mycity = e.value;
    console.log('current city ' + e.value);
    this.cityset = true;
    }
  });
 }

removecity()
{
  this.mycity = 'default';
  this.cityset = false;
  Storage.remove({key : 'mycity'}).then(()=>{
    console.log('city reset successful');
  });
}

}
