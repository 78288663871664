import { Injectable } from '@angular/core';
import { Storage } from '@capacitor/storage';

@Injectable({
  providedIn: 'root'
})
export class UserstateService {
  isloggedin = false;
  uid: number;
  dispname: string;
  isseller: boolean;
  isagent: boolean;
  token: string;
  data: any;
  rxdata: any;
  constructor() {
    this.checkandset();
  }


  checkandset()
  {
    console.log('check and set called');
    Storage.get({key : 'authdata'}).then((e)=>{
      if(!e || !e.value){
        console.log('check and set result : not logged in');
      }
      else
      {
      this.rxdata = JSON.parse(e.value);
      console.log('check and set result ' + this.rxdata.uid);
      this.setloginx(this.rxdata.uid, this.rxdata.dispname, this.rxdata.isseller, this.rxdata.isagent, this.rxdata.token);
      }
    });
  }

  getloginstate()
  {
    return this.isloggedin;
  }
  getuid()
  {
    return this.uid;
  }
  getuhash()
  {
    return this.token;
  }

  logout()
  {
    this.isloggedin = false;
    this.uid = null;
    this.token = null;
  }

  setlogin(rxuid: number, rxdispname: string, rxisseller: boolean, rxisagent: boolean, rxtoken: string){
    this.uid = rxuid;
    this.dispname = rxdispname;
    this.isseller = rxisseller;
    this.isagent = rxisagent;
    this.token = rxtoken;
    this.isloggedin = true;

    this.data = JSON.stringify({uid : this.uid, dispname : this.dispname, isseller : this.isseller,
    isagent : this.isagent, token : this.token});

    Storage.set({key:'authdata', value:this.data}).then(()=>{ console.log('stored'); });


  }
  setloginx(rxuid: number, rxdispname: string, rxisseller: boolean, rxisagent: boolean, rxtoken: string){
    this.uid = rxuid;
    this.dispname = rxdispname;
    this.isseller = rxisseller;
    this.isagent = rxisagent;
    this.token = rxtoken;
    this.isloggedin = true;

  }
  setlogout()
  {
    Storage.remove({key : 'authdata'}).then(()=>{
      console.log('logout successful');
      this.logout();
    });
  }
  testoutput()
  {
    setTimeout(() => {
      console.log( 'test output ' + this.token );
    }, 100);
  }

}
